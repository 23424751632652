<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div class="rn-page-title-area pt--120 pb--190 bg_image bg_image--1">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center rn-page-title pt--100">
              <h2 class="heading-title theme-gradient">
                Grant Cycle A Proposals
              </h2>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start Portfolio Details Area  -->
    <div class="rn-portfolio-details ptb--120 bg_color--1">
      <v-container>
        <v-row>
          <v-col>
            <div class="portfolio-details">
              <div class="row row--35 mb--50">
                <div class="col-lg-12">
                  <div class="inner">
                    <div class="section-title">
                      <h1 class="heading-title">
                        Investigating the Effectiveness of Gamification in
                        Higher Education
                      </h1>
                      <p class="description">
                        Grant:
                        <a
                          href="https://www.spencer.org/grant_types/small-research-grant"
                          target="_blank"
                          >Spencer Foundation Small Research Grant</a
                        >
                      </p>
                      <h4>Project Personnel</h4>
                      <p>Principal Investigator: Cody Squadroni<br>
Co-Principal Investigator: TBD</p>
                      <h4>Proposal Summary</h4>
                      <p>Gamification has gained increasing attention in recent years as a promising approach to enhancing student engagement and motivation in higher education. However, there is a lack of empirical evidence on the effectiveness of gamification in improving academic outcomes in higher education. This study aims to fill this gap by conducting a comparative study of the effectiveness of gamification in higher education across multiple disciplines and institutions.</p>

<p>The project will use a mixed-methods approach to investigate the impact of gamification on student engagement, motivation, and academic performance. The study will compare a gamified learning environment with a traditional learning environment and will be conducted across three institutions. The project will use quantitative and qualitative data collection methods, including surveys, interviews, and focus groups.</p>
<p>The findings of this study will contribute to the development of evidence-based practices for integrating gamification in higher education and will inform the design of future gamified learning interventions. The project outcomes will be disseminated through academic publications and presentations and made available to educators and researchers interested in gamification in higher education.</p>

                      <p class="description">
                        Start Date: August 1, 2023<br />
                        End Date: July 31, 2024
                      </p>
                      <h5>Central Research Questions</h5>
                      <p>
                        <ol>
                            <li>How does the use of
                        gamification in higher education impact student
                        motivation and engagement? </li>
                            <li>What are the best practices for implementing gamification in higher education, and how can they be used to improve student learning outcomes?</li>
                        </ol>
                      </p>
                      <h5>Project Summary</h5>
                      <p>
                        The use of gamification, or the application of game elements in non-game contexts, has gained popularity in higher education as a way to improve student engagement and motivation. However, little is known about the effectiveness of gamification in higher education and the best practices for its implementation. This research project aims to address this gap by investigating the impact of gamification on student motivation and engagement, as well as identifying best practices for implementing gamification in higher education. The findings from this study will provide valuable insights for educators and policymakers seeking to improve student learning outcomes through the use of gamification.
                      </p>
<h4>Budget and Budget Justification</h4>
<h5>Salaries</h5>
<p>PI: $20,000<br>Graduate Student Research Assistant: $16,000</p>
<h5>Benefits</h5>
<p>PI Benefits: $3,000<br>Graduate Student Research Assistant Benefits: $1,000</p>
<h5>Travel</h5>
<p>Project Travel: $2,000<br>Conference or Dissemination Travel: $2,000</p>
<h5>Equipment and Software</h5>
<p>Equipment: $1,000<br>Software: $1,000</p>
<h5>Project Expenses</h5>
<p>Supplies: $1,000<br>
Participant Stipends/Costs: $1,000<br>
Communication: $1,000<br>
Transcription: $1,000</p>
<h6>Total Budget: $50,000</h6>
<h5>Budget Justification</h5>
<p>The PI and Graduate Student Research Assistant will be responsible for conducting the research project, which includes data collection, analysis, and report writing. The PI will receive a salary of $20,000 and the Graduate Student Research Assistant will receive a salary of $16,000. Both positions will also receive benefits totaling $4,000. The budget includes funds for project travel and conference or dissemination travel, which will be used to present the findings of the research project at academic conferences and events. The budget also includes funds for equipment and software necessary for data collection and analysis, as well as project expenses such as supplies, participant stipends/costs, communication, and transcription.</p>
<h4>Subcontracts</h4>
<p>None</p>
<h4>Proposal Narrative</h4>
<h5>Introduction</h5>
<p>Gamification is the application of game elements in non-game contexts, and it has gained popularity in higher education as a way to improve student engagement and motivation. However, there is a lack of research on the effectiveness of gamification in higher education and the best practices for its implementation. This research project aims to address this gap by investigating the impact of gamification on student motivation and engagement, as well as identifying best practices for implementing gamification in higher education.</p>
<h5>Rationale</h5>
<p>Previous studies have shown that gamification has the potential to improve student engagement and motivation in various educational contexts. However, there is limited research on the effectiveness of gamification in higher education. This study will fill this gap by investigating the impact of gamification on student motivation and engagement in higher education, as well as identifying best practices for its implementation.</p>
<h5>Methodology</h5>
<p>This study will use a mixed-methods approach to investigate the impact of gamification in higher education. The study will be conducted at a large public university in the United States, and participants will be undergraduate students enrolled in courses that use gamification. Data will be collected through surveys, interviews, and focus groups. The survey will be used to collect


quantitative data on student engagement and motivation, as well as their perceptions of the effectiveness of gamification in their courses. Interviews and focus groups will be used to collect qualitative data on students' experiences with gamification, their perceptions of its effectiveness, and their suggestions for improvement.</p>
<p>The study will also include an analysis of student performance data, such as grades and course completion rates, to determine if there is a correlation between gamification and improved learning outcomes. The study will employ a quasi-experimental design, with a treatment group of students enrolled in courses that use gamification and a control group of students enrolled in courses that do not use gamification.</p>
<p>Data analysis will involve both quantitative and qualitative methods. Quantitative data will be analyzed using descriptive statistics, correlations, and regression analysis. Qualitative data will be analyzed using thematic analysis.</p>
<h5>Expected Outcomes</h5>
<p>The findings from this study will contribute to the growing body of research on the effectiveness of gamification in higher education. Specifically, the study will provide insights into the impact of gamification on student motivation and engagement, as well as identify best practices for implementing gamification in higher education. The study will also contribute to a better understanding of the relationship between gamification and student learning outcomes.</p>
<h5>Dissemination</h5>
<p>The findings from this study will be disseminated through academic conferences, peer-reviewed publications, and presentations to educators and policymakers. The study will also be shared with the participating university and other universities interested in implementing gamification in their courses.</p>
<h5>Conclusion</h5>
<p>This research project aims to investigate the effectiveness of gamification in higher education and identify best practices for its implementation. By filling the gap in the current literature, this study will contribute to a better understanding of the potential of gamification to improve student engagement, motivation, and learning outcomes in higher education.</p>

                      <div class="portfolio-details-btn mt--30">
                        <router-link
                          class="btn-default btn-border"
                          to="/LDTE5870-3"
                          >Back to LDTE 5870</router-link
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Portfolio Details Area  -->

    <Footer />
  </div>
</template>

<script>
import Header from "../../components/header/HeaderThree";
import Footer from "../../components/footer/FooterTwo";
import feather from "feather-icons";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      logo: require("../../assets/images/logo/logo.png"),
      items: [
        {
          thumb: require("../../assets/images/portfolio/portfolio-big-03.jpg"),
          src: "https://www.youtube.com/watch?v=ZOoVOfieAF8",
        },
      ],
      listOne: [
        {
          id: 1,
          icon: "check",
          desc: `5 PPC Campaigns the majority.`,
        },
        {
          id: 2,
          icon: "check",
          desc: `Digital Marketing going to.`,
        },
        {
          id: 3,
          icon: "check",
          desc: ` Marketing Agency passage of.`,
        },
        {
          id: 4,
          icon: "check",
          desc: ` Seo Friendly you are going.`,
        },
      ],
      socialList: [
        {
          url: "https://www.facebook.com/",
          icon: "facebook",
        },
        {
          url: "https://www.linkedin.com/",
          icon: "linkedin",
        },
        {
          url: "https://instagram.com/",
          icon: "instagram",
        },
        {
          url: "https://twitter.com/",
          icon: "twitter",
        },
      ],
      moreProjects: [
        {
          src: require("../../assets/images/portfolio/related-image-01.jpg"),
          title: "Digital Analysis",
          categorie: "Technique",
        },
        {
          src: require("../../assets/images/portfolio/related-image-02.jpg"),
          title: "Plan Management",
          categorie: "Planning",
        },
      ],
      index: null,
    };
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
